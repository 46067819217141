import { repositoryItems } from "@/constants/repositoryItems";
import * as api from "@/constants/api";

/****************** Structure of each sidebar menu item ***********************
 {
    title: "",            // [required] title of the menu
    icon: "",             // [required] icon of the menu
    prefixes: [],         // [optional] an array of string. if current route has
                          // any of the string from this array, then the menu will
                          // be active in UI.
    defaultAction: null,  // [optional] function to call on menu click
    routeName: "",        // [optional] name of the route to go on menu click
    children: [],         // [optional] an array if this is a submenu
    requirePrivilege: {   // [optional] hide if user can't access this api path
      path: "",           // [required] api path
      method: "",         // [optional] api method
    }
 }
 *****************************************************************************/

export const sidebarItems = [
  {
    routeName: "dashboard",
    title: "Dashboard",
    icon: "mdi-desktop-mac-dashboard",
    exact: true,
  },
  {
    routeName: "checker.index",
    title: "Checker",
    icon: "mdi-set-split",
    requirePrivilege: { path: api.CHECKER_LIST },
    exact: true,
  },
  {
    title: "Users",
    icon: "mdi-account-group",
    prefixes: ["user", "role", "resource"],
    children: [
      {
        routeName: "user.index",
        title: "Users",
        icon: "mdi-account-supervisor-circle-outline",
        requirePrivilege: { path: api.USER_LIST },
        exact: true,
      },
      // {
      //   routeName: "role.index",
      //   title: "Roles",
      //   icon: "mdi-drama-masks",
      // },
      {
        routeName: "resource.index",
        title: "Resources",
        icon: "mdi-server-network",
        requirePrivilege: { path: api.USER_RESOURCE_LIST },
        exact: true,
      },
      {
        routeName: "user.deleted.index",
        title: "Deleted Users",
        icon: "mdi-account-off-outline",
        requirePrivilege: { path: api.USER_LIST },
        exact: true,
      },
    ],
  },
  {
    routeName: "mfs.index",
    title: "Onboarding (MFS)",
    icon: "mdi-human-edit",
    requirePrivilege: { path: api.ONBOARDING_MFS_REGISTRATIONS },
    exact: true,
  },
  {
    //routeName: "repository.index",
    title: "Repositories",
    icon: "mdi-file-document-multiple",
    prefixes: ["repository"],
    // defaultAction: ($vue) => {
    //   if ($vue && $vue.$route.name !== "repository.index") {
    //     $vue.$router.push({ name: "repository.index" });
    //   }
    // },
    children: Object.keys(repositoryItems).map((el) => ({
      routeName: repositoryItems[el].routeName,
      routeParams: repositoryItems[el].routeParams,
      title: repositoryItems[el].title,
      icon: repositoryItems[el].icon,
      requirePrivilege: { path: api.REPO_ITEM_LIST.replace(":item:", el) },
      exact: true,
    })),
  },
  {
    title: "NID OCR",
    icon: "mdi-format-color-text",
    prefixes: ["ocr-request", "parse-request", "nid-ocr-card", "billing"],
    children: [
      {
        routeName: "nid-ocr-card.index",
        title: "NID OCR Card",
        icon: "mdi-image-filter-center-focus",
        requirePrivilege: { path: api.NID_OCR_CARDS },
      },
      {
        routeName: "ocr-request.index",
        title: "OCR Request",
        icon: "mdi-format-font",
        requirePrivilege: { path: api.OCR_REQUESTS },
      },
      {
        routeName: "parse-request.index",
        title: "Parse Request",
        icon: "mdi-id-card",
        requirePrivilege: { path: api.PARSE_REQUESTS },
      },
      {
        routeName: "billing.index",
        title: "Billing",
        icon: "mdi-receipt",
        requirePrivilege: { path: api.NID_OCR_BILLING_LIST },
      },
    ],
  },
  {
    title: "Voter Info",
    icon: "mdi-share-variant-outline",
    prefixes: ["nid-scraper", "porichoy-request"],
    children: [
      {
        routeName: "nid-scraper.index",
        title: "NID Scraper",
        icon: "mdi-file-document",
        prefixes: ["nid-scraper"],
        requirePrivilege: { path: api.NID_SCRAPER_REQUESTS },
      },
      {
        routeName: "porichoy-request.index",
        title: "Porichoy Request",
        icon: "mdi-sparkles",
        prefixes: ["porichoy-request"],
        requirePrivilege: { path: api.PORICHOY_REQUESTS },
      },
    ],
  },
  {
    title: "Facelab",
    icon: "mdi-face",
    prefixes: ["liveness-request", "match-request", "request-image"],
    children: [
      {
        routeName: "liveness-request.index",
        title: "Liveness Request",
        icon: "mdi-drama-masks",
        requirePrivilege: { path: api.FACE_LIVENESS_REQUESTS },
      },
      {
        routeName: "match-request.index",
        title: "Match Request",
        icon: "mdi-transition-masked",
        requirePrivilege: { path: api.FACE_MATCH_REQUESTS },
      },
      {
        routeName: "request-image.index",
        title: "Request Image",
        icon: "mdi-image",
        requirePrivilege: { path: api.FACE_REQUEST_IMAGES },
      },
    ],
  },
  {
    title: "Management",
    icon: "mdi-cog-box",
    prefixes: ["management"],
    children: [
      {
        routeName: "management.configuration",
        title: "Configuration",
        icon: "mdi-tune-vertical",
        requirePrivilege: { path: api.CONFIG_GET_ALL },
      },
      {
        routeName: "management.status",
        title: "Status",
        icon: "mdi-google-downasaur",
        requirePrivilege: { path: api.ACTUATOR_ROUTES_HEALTH },
      },
      {
        routeName: "management.logs",
        title: "Logs",
        icon: "mdi-file-document",
        requirePrivilege: { path: api.ACTUATOR_ROUTES_LOGFILE },
      },
      {
        routeName: "management.http-trace",
        title: "HTTP Trace",
        icon: "mdi-format-list-text",
        requirePrivilege: { path: api.ACTUATOR_ROUTES_HTTP_TRACE },
      },
    ],
  },
  {
    title: "Test",
    icon: "mdi-test-tube",
    prefixes: ["test"],
    children: [
      {
        routeName: "test.liveness",
        title: "Face Liveness",
        icon: "mdi-ab-testing",
        requirePrivilege: { path: api.LIVENESS_CHECK },
      },
      {
        routeName: "test.match",
        title: "Face Match",
        icon: "mdi-test-tube-off",
        requirePrivilege: { path: api.FACE_MATCH_CHECK },
      },
      {
        routeName: "test.nid-ocr",
        title: "NID OCR",
        icon: "mdi-test-tube",
        requirePrivilege: { path: api.OCR_NID },
      },
      {
        routeName: "test.nid-info-scraper",
        title: "NID Scraper",
        icon: "mdi-expansion-card",
        requirePrivilege: { path: api.NID_SCRAPER_SCRAPE },
      },
      {
        routeName: "test.nid-info-porichoy",
        title: "Porichoy",
        icon: "mdi-identifier",
        requirePrivilege: { path: api.PORICHOY_SCRAPE },
      },
    ],
  },
];
